<template>
  <v-app>
    <Loader :is-visible="loading" />

    <v-app-bar v-if="!this.$route.path.includes('login')" app class="primary">
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-toolbar-title class="headline">
        <template v-if="!isSala">
          <span>
            Painel de Gestão de Relacionamento com os Municípios -

            <img
              :src="logos.logoBranca"
              style="width: 120px; margin-left: 10px"
            />
          </span>
          <img
            src="./assets/img/sebrae.png"
            style="width: 83px; margin-left: 10px"
          />
        </template>
        <template v-else>
          <img
            src="./assets/img/sebrae.png"
            style="width: 83px; margin-right: 10px"
          />
          <selo-logo></selo-logo>
          <div class="infos-cidade">
            <template v-if="isSala"> </template>
            <div>{{ getFirstName() }}</div>
            <div>|</div>
            <div class="buttonSair" @click="logout">sair</div>
          </div>
        </template>
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>

    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import { atualUfDefinition } from "./helpers/utils";
import SeloLogo from "./components/seloLogo/seloLogo.vue";
import { configuracaoAPI } from "@/apis/configuracao";

export default {
  name: "App",
  components: {
    Loader,
    SeloLogo,
  },
  computed: {
    ...mapGetters([
      "isLogged",
      "isAuthenticated",
      "isConsultor",
      "isAdmin",
      "loading",
      "user",
      "uf",
      "logo",
      "svg",
      "isSala",
      "tipoMapa",
      "title",
      "logos",
      "isDT",
      "isCE",
    ]),
  },
  created() {
    this.mudaUf();
  },
  data() {
    return {
      drawer: false,
      estado: process.env.VUE_APP_ESTADO,
      logouf: process.env.VUE_APP_LOGO,
    };
  },
  methods: {
    /** Não esquecer de mapear no mapActions o nome da store antes de setar ele no mudaUF */
    ...mapActions([
      "setToken",
      "setUser",
      "setAdmin",
      "setExpired",
      "setUf",
      "setLogo",
      "setSvg",
      "setIsSala",
      "setForcedLogout",
      "setConfig",
      "setIsTerritorio",
      "setIsDT",
      "setLogos",
      "setTitle",
      "setIsCE",
    ]),
    getFirstName() {
      var user = JSON.parse(localStorage.getItem("user"));
      if (user) return user.nome.split(" ")[0];

      return "";
    },
    mudaUf() {
      let hrf = window.location.href;

      this.setUf(atualUfDefinition.uf);
      this.setIsSala(atualUfDefinition.isSala);
      this.setIsDT(atualUfDefinition.isDT);

      this.setIsTerritorio(this.$route.name == "dashboard-territorios");
      this.setLogo(atualUfDefinition.logo);
      this.setLogos(atualUfDefinition.logos);
      this.setSvg(atualUfDefinition.svg);
      this.setTitle(atualUfDefinition.title);
      this.setIsCE();

      setInterval(function () {
        document.documentElement.style.setProperty(
          "--corPrimaria",
          atualUfDefinition.corPrimaria
        );
      }, 100);

      document.title = this.title;
      document.getElementById("favicon").href = this.logos.logoEncurtada;
      console.log(
        "🚀 ~ mudaUf ~ this.logos.logoEncurtada:",
        this.logos.logoEncurtada
      );
    },
    enableInterceptor() {
      this.$http.interceptors.request.use(
        (config) => {
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      this.$http.interceptors.response.use(
        (response) => {
          return response;
        },
        (err) => {
          if (err.response.status === 401) {
            this.setExpired(true);
            this.$notification.error(
              "Sessão expirada, por favor entre novamente",
              { timer: 10, ignoreDuplicates: true }
            );
            this.$store.dispatch("logout").then(() => {
              this.$router.push("/login");
            });
          }
          return Promise.reject(err);
        }
      );
    },
    logout() {
      this.setForcedLogout(true);
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/auth/login");
      });
    },
  },
  async mounted() {
    try {
      const { data: config } = await configuracaoAPI.list();
      this.setConfig(config[0]);
    } catch (error) {
      this.$notification.error("Erro ao listar as configuracoes");
      console.log("error: ", error);
    }
    this.enableInterceptor();
    this.mudaUf();
  },
};
</script>

<style lang="scss">
@import "./style.scss";
@import "@/variables.scss";

.painel {
  margin-right: 10px;
  color: $cor-selo-texto !important;
}

.infos-cidade {
  margin-left: auto;
  display: flex;
  column-gap: 1rem;
  margin-right: 1rem;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  align-items: center;

  .icon-help {
    cursor: pointer;
  }
  .help {
    color: #fff;
  }

  .help:hover {
    background-color: #284395 !important;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff !important;
    .icon-help {
      color: #fff !important;
    }
  }

  .buttonSair {
    background-color: #5c8bb9;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
  }

  .buttonSair:hover {
    background-color: #284395;
  }
}
</style>
